/*CSS for Global use and the menu*/

@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

/* latin */
@font-face {
	font-family: 'Montserrat Subrayada';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserratsubrayada/v12/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQH8MaOY.woff2)
		format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
		U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'SN icons';
	src: url(./images/SNIcons-Modifided.ttf);
}

* {
	scrollbar-width: none; /* Firefox implementation */
}

body {
	/* This is the original body tag temporarily diabled to render correctly margin: 0px;*/
	background-image: url(https://dl.dropboxusercontent.com/s/d5cmnn74xp641h0/background.png?raw=1);
	background-size: cover;
	margin: 0;
	scrollbar-width: none; /* Firefox */
	display: flexbox;
	font-size: 16px;
	flex-direction: column;
	overflow: hidden;
	overflow-x: hidden;
	/* white-space: pre-line; */
}

button {
	background-color: transparent;
	border: transparent;
}

.Toastify__toast {
	background-color: rgba(19, 24, 59, 0.95) !important;
	box-shadow: 10px 10px 10px rgba(39, 248, 238, 0.678) !important;
	color: silver !important;
	border: 1px double #67fefe !important;
	border-top-left-radius: 1em 4em !important;
	border-top-right-radius: 1em !important;
	border-bottom-left-radius: 1em !important;
	border-bottom-right-radius: 1em 4em !important;
}

.Toastify__progress-bar {
	background: #67fefe !important;
	padding-top: 5px !important;
	height: 2px !important;
}

.Toastify__close-button {
	color: silver !important;
}

html {
	font-family: 'Ubuntu';
	color: silver;
	scrollbar-width: none;
}

@keyframes border {
	100% {
		border-color: transparent;
	}
}

@keyframes icons {
	100% {
		border-color: transparent;
	}
}

section {
	margin: 0;
	-ms-overflow-style: none;
	scrollbar-width: none; /* Firefox */
	overflow: scroll;
	display: flex;
}

a:link,
a:visited {
	color: inherit;
	text-decoration: none;
}

p {
	color: silver;
}

span {
	color: silver;
	margin: auto;
	text-align: center;
}

header {
	text-align: center;
	line-height: 100px;
	font-size: 3.125em;
	margin: 0;
}

.h1 {
	text-align: center;
	line-height: 100px;
	margin: 0;
	margin-bottom: 30px;
	color: silver;
	padding: 10px;
}

.h1Mobile {
	text-align: center;
	margin: 0;
	margin-top: 1em;
	margin-bottom: 1em;
	color: silver;
	padding: 0.5em;
}

.h1mob {
	text-align: center;
	line-height: 100px;
	margin-top: 5px;
	margin-bottom: 5px;
	color: silver;
}

.h2 {
	margin-left: 10px;
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: #67fefe;
	padding: 10px;
	margin-bottom: 0.5em;
}

.h2_2 {
	margin-left: 10px;
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: silver;
	padding: 10px;
	margin-bottom: 0.5em;
}

.h3 {
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: #67fefe;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 30px;
	font-size: 1.875em;
	padding: 10px;
	padding-right: 20px;
}

.h4 {
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: #67fefe;
	margin: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 1.25em;
	padding: 10px;
	padding-right: 20px;
}

.h4_2 {
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: silver;
	margin-left: 3%;
	margin-top: 20px;
	margin-bottom: 10px !important;
	font-size: 1.875em;
	padding: 10px;
	padding-right: 20px !important;
}

.h4_mob {
	font-size: 1em !important;
}

.h5 {
	width: max-content;
	color: #67fefe;
	padding-right: 20px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 2.1875em;
	padding: 10px;
}

.h6 {
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: silver;
	padding-right: 10px !important;
	padding-bottom: 3px;
	font-size: 1.75em !important;
}

.glow:hover,
h5 {
	animation: glow 1s ease-in-out infinite alternate;
}

.activetab {
	animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #4dff88, 0 0 40px #4dff88, 0 0 50px #4dff88,
			0 0 60px #4dff88, 0 0 70px #4dff88;
	}

	to {
		text-shadow: 0 0 11px #fff, 0 0 21px #00ff55, 0 0 31px #00ff55, 0 0 41px #00ff55, 0 0 51px #00ff55,
			0 0 61px #00ff55, 0 0 71px #00ff55;
	}
}

@keyframes fire {
	from {
		text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ff884d, 0 0 40px #ff884d, 0 0 50px #ff884d,
			0 0 60px #ff884d, 0 0 70px #ff884d;
	}

	to {
		text-shadow: 0 0 11px #fff, 0 0 21px #ff0a00, 0 0 31px #ff0a00, 0 0 41px #ff0a00, 0 0 51px #ff0a00,
			0 0 61px #ff0a00, 0 0 71px #ff0a00;
	}
}

.menuwrapper {
	padding: 0;
	margin: 0;
}

.menu {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 3s reverse;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.25em;
	background: rgba(15, 18, 19, 0.5);
	margin-bottom: 5px;
	/* height: 50px; */
	height: 1.66em;
	padding: 10px;
}

.menubar {
	text-align: center;
	font-size: 1.375em;
	padding-top: 28px;
	margin-bottom: 5px;
	margin-right: 10px;
	display: block;
}

.menubarright {
	text-align: center;
	font-size: 1.375em;
	padding-top: 28px;
	margin-bottom: 5px;
	margin-left: 10px;
	display: block;
	transition: transform 0.3s ease-in-out;
}

.iconbox {
	border: 2px solid rgb(103, 254, 254, 0.1);
	border-radius: 1em;
	animation: icons 10s reverse;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;
	font-family: 'SN Icons';
	background: rgba(15, 18, 19, 0.5);
	height: 2.35em;
	padding: 10px;
}

.iconbox:hover p.glow {
	animation: glow 1s ease-in-out infinite alternate;
}

.iconsbar {
	border-right: 2px solid #727b7e;
	border-left: 2px solid #727b7e;
	border-radius: 1em 4em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 4em;
	animation: icons 10s reverse;
	text-align: center;
	font-size: 1.375em;
	/* width: 50px; */
	width: 2.275em;
	margin-top: 25px;
	margin-bottom: 5px;
	margin-right: 10px;
	margin-left: 10px;
	background: rgba(15, 18, 19, 0.5);
	font-family: 'Montserrat Subrayada';
	height: 95vh;
	padding: 10px;
}

.iconsbarright {
	border-right: 2px solid #727b7e;
	border-left: 2px solid #727b7e;
	border-radius: 4em 1em;
	border-bottom-left-radius: 4em;
	border-bottom-right-radius: 1em;
	animation: icons 10s reverse;
	text-align: center;
	font-size: 1.375em;
	/* width: 50px; */
	width: 2.275em;
	margin-top: 25px;
	margin-bottom: 5px;
	margin-right: 10px;
	margin-left: 10px;
	background: rgba(15, 18, 19, 0.5);
	font-family: 'Montserrat Subrayada';
	height: 95vh;
	padding: 10px;
}

.iconsbarmin {
	box-shadow: 2px 2px 10px rgba(39, 248, 238, 0.678);
	background-color: rgba(19, 24, 59, 0.25);
	border-right: 1px solid #67fefe;
	border-bottom: 1px solid #67fefe;
	border-top: 1px solid #67fefe;
	border-top-right-radius: 4em;
	border-bottom-right-radius: 4em;
	font-size: 1.375em;
	width: auto;
	padding: 2px;
	margin-top: 15px;
	position: absolute;
	top: 0.5%;
	left: 0;
	transition: transform 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	color: #67fefe;
	height: 95%;
	overflow: scroll;
	z-index: 10;
}

.iconsbarminclosed {
	box-shadow: 2px 2px 10px rgba(39, 248, 238, 0.678);
	background-color: rgba(19, 24, 59, 0.25);
	border-right: 1px solid #67fefe;
	border-bottom: 1px solid #67fefe;
	border-top: 1px solid #67fefe;
	border-top-right-radius: 4em;
	border-bottom-right-radius: 4em;
	font-size: 1.375em;
	width: auto;
	padding: 2px;
	position: absolute;
	left: 0;
	top: 45%;
	transition: transform 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	color: #67fefe;
	height: 10%;
	overflow: auto;
	z-index: 10;
}

.darkBGM {
	backdrop-filter: blur(5px) saturate(50%);
	-webkit-backdrop-filter: blur(5px) saturate(50%);
	background-color: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	z-index: 5;
}

.mobiletab {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.iconsbarminrightclosed {
	box-shadow: -2px 2px 10px rgba(39, 248, 238, 0.678);
	background-color: rgba(19, 24, 59, 0.25);
	border-left: 1px solid #67fefe;
	border-bottom: 1px solid #67fefe;
	border-top: 1px solid #67fefe;
	border-top-left-radius: 4em;
	border-bottom-left-radius: 4em;
	font-size: 1.375em;
	width: auto;
	padding: 2px;
	position: absolute;
	top: 45%;
	right: 0;
	display: flex;
	justify-content: center;
	color: #67fefe;
	height: 10%;
	overflow: hidden;
	z-index: 10;
}

.iconsbarminright {
	box-shadow: -2px 2px 10px rgba(39, 248, 238, 0.678);
	background-color: rgba(19, 24, 59, 0.25);
	border-left: 1px solid #67fefe;
	border-bottom: 1px solid #67fefe;
	border-top: 1px solid #67fefe;
	border-top-left-radius: 4em;
	border-bottom-left-radius: 4em;
	font-size: 1.375em;
	width: auto;
	padding: 2px;
	margin-top: 15px;
	position: absolute;
	top: 0.5%;
	right: 0;
	transition: transform 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	color: #67fefe;
	height: 95%;
	overflow: hidden;
	z-index: 10;
}

.spinnercw {
	animation-name: spinnercw;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-duration: 5s;
	transform-style: preserve-3d;
	text-align: center;
}
@keyframes spinnercw {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(-360deg);
	}
}

.content {
	border: 2px solid #727b7e;
	border-radius: 1em 4em;
	animation: icons 5s reverse;
	margin-top: 10px;
	margin-bottom: 5px;
	background: rgba(15, 18, 19, 0.75);
	overflow: scroll;
	width: 100%;
	height: 95vh;
	background-image: none;
	padding: 10px;
}

.maincontainer {
	width: 100%;
	height: 100%;
	border: none;
	font-family: 'Ubuntu';
	color: silver;
	background-image: none;
	overflow: scroll;
}

/*CSS For the login, registration and account/bank pages 
and under development page + buttons in general*/

.loginbox,
.DevNotice {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	/* width: 500px; */
	width: 31.3em;
	display: block;
	margin: auto;
	margin-top: 1em;
	padding: 10px;
}

.loginboxmobile {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	margin: auto;
	margin-top: 2em;
	width: auto;
	max-width: 400px;
	height: auto;
	max-height: 900px;
}

.DevNotice {
	margin-top: 15%;
}

.notice {
	font-size: 1.125em;
	text-align: center;
	margin-top: 100px;
	margin-bottom: 100px;
}

.notice2 {
	font-size: 1.125em;
	text-align: center;
	margin: 25px;
}

.notice3 {
	font-size: 1.125em;
	text-align: left;
	margin: 1em;
}

.form-group {
	margin-bottom: 10px;
	margin: auto;
	width: max-content;
	/* height: 43px; */
	height: 2.69em;
	padding-bottom: 13px;
}

.fg-left {
	margin: 0 !important;
	margin-left: 2em !important;
}

.form-group-mobile {
	margin-top: 10px !important;
	margin-bottom: 10px;
	margin: auto;
	width: max-content;
	/* height: 43px; */
	height: 2.69em;
	padding-bottom: 13px;
}

.form-group p {
	padding: 0;
	margin: 5px;
	font-size: 0.8125em;
	padding-left: 10px;
}

.pw_text {
	margin: auto;
	text-align: center;
	font-size: 0.8125em;
	padding: 0;
}

.pw_text_red {
	color: red;
	margin: auto;
	text-align: center;
	font-size: 0.8125em;
	padding: 0;
}

.pw_text_green {
	color: green;
	margin: auto;
	text-align: center;
	font-size: 0.8125em;
	padding: 0;
}

.profile_buttons {
	display: flex;
	position: relative;
	width: 90%;
	height: 2em;
	margin: auto;
	margin-bottom: 0.5em;
}

.logout_b {
	position: absolute;
	right: 0;
}

.admin_b {
	display: block;
}

.defcon_b {
	position: absolute;
	right: 45%;
}

.defcon_bm {
	position: absolute;
	right: 25%;
}

.defcon_message {
	width: 95%;
	margin: 1em;
	padding: 0.5em;
	white-space: normal;
}

.signin {
	width: 7.5em;
	height: 1.5em;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	padding-bottom: 1px;
	line-height: 5px;
	margin-right: 5px;
}

.signintall {
	width: 9em;
	height: 3em;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	padding-bottom: 1px;
	margin-right: 5px;
}

.defconbutton {
	width: 7.5em;
	height: 1.5em;
	color: red;
	border: 2px double red;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;

	animation: pulse 2s infinite;
	background-color: inherit;
	padding-bottom: 1px;
	line-height: 5px;
	margin-right: 5px;
}

.dc_bm {
	width: 9em !important;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0.3);
	}
	70% {
		-webkit-box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0);
	}
	100% {
		-webkit-box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0.7);
	}
}
@keyframes pulse {
	0% {
		-moz-box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0.3);
		box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0.3);
	}
	70% {
		-moz-box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0);
		box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0);
	}
	100% {
		-moz-box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0.7);
		box-shadow: 1px 1px 16px 7px rgba(242, 46, 75, 0.7);
	}
}

.defconbutton:hover {
	background-color: red;
	color: black;

	transform: scale(1.05);
}

.signinwide {
	width: 12em !important;
}

.forgeback {
	width: 100vw;
	height: 100vh;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-image: url(https://dl.dropboxusercontent.com/s/jkgnebwrbfa1i1a/forge.png?raw=1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.bosback {
	width: 100vw;
	height: 100vh;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-image: url(https://dl.dropboxusercontent.com/s/dgrysf57auhgwcn/vault4.png?raw=1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.leaderback {
	width: 100vw;
	height: 100vh;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-image: url(https://dl.dropboxusercontent.com/s/s17fyxnmldk0552/leaderboard1.png?raw=1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.forging {
	animation: glow 1s ease-in-out infinite;
}

.melting {
	animation: fire 1s ease-in-out infinite;
}

.signinwide2 {
	width: 10em !important;
}

.signinmini {
	width: 2em !important;
}

.signinmini2 {
	width: 5em !important;
}

.signin2 {
	/* width: 150px;
	height: 30px; */
	width: 7.5em;
	height: 1.5em;
	color: rgba(192, 192, 192, 0);
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	background-image: url(https://dl.dropboxusercontent.com/s/hmm8fmd1427fu0c/googlesignin.svg?raw=1);
	background-size: cover;
	background-position: center;
	background-color: #67fefe;
	margin-right: 5px;
}

.signin3 {
	min-width: 9.5em;
	height: 1.9em;
	font-size: 1em !important;
}

.signinmini3 {
	width: 5em !important;
	font-size: 1em !important;
}

.signin4 {
	min-width: 7.5em !important;
	height: 1.6em !important;
}

.signin4 p {
	font-size: 0.7em;
}

.disabled,
.disabled:hover {
	color: silver !important;
	border: 2px double silver !important;
	background-color: #2f3131 !important;
	box-shadow: none !important;
	transform: scale(1) !important;
}

.signinMetaMask {
	/* width: 150px;
	height: 30px; */
	width: 7.5em;
	height: 1.5em;
	color: rgba(192, 192, 192, 0);
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	background-image: url(https://dl.dropboxusercontent.com/s/iu1kyqgn9siczd8/mmlogin.svg?raw=1);
	background-size: cover;
	background-position: center;
	background-color: #67fefe;
}

button.signin:hover,
button.signintall:hover,
button.signin2:hover,
button.signinMetaMask:hover,
button.banking:hover,
.closeBtn:hover,
.tokenadress:hover,
.tokenadress2:hover,
.tokenaddressmob:hover,
.salebuttonmob:hover,
.salebutton:hover {
	background-color: #67fefe;
	color: black;
	box-shadow: rgba(79, 248, 234, 0.35) 0 -25px 18px -14px inset, rgba(79, 248, 234, 0.25) 0 1px 2px,
		rgba(79, 248, 234, 0.25) 0 2px 4px, rgba(79, 248, 234, 0.25) 0 4px 8px,
		rgba(79, 248, 234, 0.25) 0 8px 16px, rgba(79, 248, 234, 0.25) 0 16px 32px;
	transform: scale(1.05);
}

button.accordion:hover,
button.accordion.is-open,
button#Defiinfobutton:hover,
button#Defiinfobutton.is-open,
button#LPtokenStake:hover,
button#LPtokenStake.is-open,
button.stakebutton:hover {
	background-color: #67fefe;
	color: black;
}

.wrap {
	display: grid;
	grid-template-columns: 100px 250px;
	margin-left: 10%;
}

.wrap1_2 {
	display: grid;
	grid-template-columns: 1fr 3fr;
	width: 99%;
	margin-left: 1em;
}

.width90 {
	width: 90% !important;
	max-width: 90% !important;
}

.wrap2 {
	display: grid;
	grid-template-columns: 7em 7em;
	margin-left: 5%;
	margin-bottom: 0.5em;
}

.wrap2_mobile {
	display: grid;
	grid-template-columns: 7em;
	margin-left: 5%;
	margin-bottom: 0.5em;
}

.wrap2_2 {
	display: grid;
	grid-template-columns: 8em 7em;
	margin-left: 5%;
	margin-bottom: 0.5em;
}

.wrap2_3 {
	display: grid;
	grid-template-columns: 11em 5em;
	margin-left: 5%;
	margin-bottom: 0.5em;
}

.wrap3 {
	display: grid;
	grid-template-columns: 7em 6em 4em 3em;
	margin-left: 5%;
	margin-bottom: 0.5em;
}

.f1 {
	color: silver;
	font-size: 1.25em;
	text-align: right;
	padding: 10px;
}

.f1_2 {
	color: silver;
	font-size: 1em;
	text-align: right;
	padding-top: 0.4em;
	margin-right: 0.5em;
	margin-top: 0;
	margin-bottom: 0;
}

.f1_22 {
	width: 7em;
}

.f1_mobile {
	color: silver;
	font-size: 1em;
	text-align: left;
	margin-bottom: 0.5em;
}

.f1_2wider {
	width: 10em !important;
}

.checkbox {
	width: 1.5em;
	height: 1em;
	margin-right: 0.5em;
}

.user_id {
	font-size: 1.25em;
	padding: 10px;
	padding-left: 50px;
	margin-left: 10%;
}

.form-input {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	/* width: 320px;
	height: 30px; */
	width: 12.8em;
	height: 1.2em;
	font-size: 1.5625em;
	margin: auto;
	color: silver;
	outline: none;
}

.form-input2 {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	height: 25px;
	font-size: 1.25em;
	margin: auto;
	color: silver;
	outline: none;
}

.form-input3 {
	max-width: 8em;
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	height: 25px;
	font-size: 1.25em;
	margin: auto;
	margin-left: 0.5em;
	color: silver;
	outline: none;
}

.form-input3 option {
	background-color: rgba(3, 4, 39, 0.9);
	color: #67fefe;
	text-align: left;
	border: none;
	border-radius: 2em;
}

.fi3_wide {
	max-width: 10em !important;
	width: 10em !important;
}

.fi3-small {
	max-width: 4em !important;
}

.fi_mini {
	max-width: 4em !important;
}

.selector {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	font-size: 1.25em;
	width: 250px;
	color: silver;

	margin-right: 20px;
}

.otherlogins {
	width: max-content;
	margin: auto;
	margin-bottom: 25px;
}

.otherloginp {
	margin: auto;
	width: max-content;
	margin-bottom: 20px;
	color: silver;
}

.profilebox {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	width: 90%;
	height: fit-content;
	padding: 25px;
	display: block;
	margin: auto;
	margin-top: 25px;
}

.profileboxmobile {
	width: 88% !important;
	max-height: 90vh !important;
	margin-top: 2vh !important;
	margin-bottom: 17em;
	overflow: scroll !important;
}

.subheaderwrap {
	display: flex;
	justify-content: space-between;
}

.refreshbutton {
	font-size: 1.5em;
	border: none !important;
	margin-top: 1em;
}

.profile {
	border: silver solid 1px;
	border-radius: 2em;
	margin: 10px;
}

.bank {
	border: silver solid 1px;
	border-radius: 2em;
	margin-bottom: 25px;
	padding: 10px;
}

.profilegrid,
.profilegridmobile {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr;
	grid-row-gap: 5px;
	grid-gap: 5px;
	padding: 10px;
	max-width: 50em;
	width: fit-content;
}

.profilegridmobile {
	grid-template-columns: 2fr 2fr 0.5fr !important;
}

.pg-settings {
	display: grid;
	grid-template-columns: 20% 30% 20%;
	grid-template-areas: 'pg1 pg2 pg3';
	grid-gap: 0.5em;
	padding: 0.5em;
}

.profilegridMobile {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start space-evenly;
	padding: 0.5em;
	max-width: 95%;
	font-size: 1em;
	margin-left: 2em;
	margin-bottom: 1em;
}

.profilegridMobile div {
	margin-right: 0.5em;
	margin-bottom: 0.5em;
}

.settingsgrid {
	display: grid;
	grid-template-columns: auto auto;
	grid-row-gap: 5px;
	padding: 3px;
}

.pg1 {
	grid-area: pg1;
}

.ps1 {
	text-align: right;
	color: silver;
	font-size: 1.125em;
	padding-top: 0.5em;
	max-height: 1.5em;
}

.pg2 {
	grid-area: pg2;
	text-align: center;
}

.ps2 {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding: 10px;
	padding-left: 25px;
	color: silver;
	padding-left: 1em !important;
	max-height: 1.3em !important;
}

.ps-left {
	padding-left: 0.6em;
}

.pg3 {
	grid-area: pg3;
}

.p2red {
	text-align: left;
	color: red;
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding: 10px;
	padding-left: 25px;
	font-size: 1.125em;
	font-weight: bolder;
}

.questionred {
	color: white;
	border-top: 5px solid aqua;
	border-bottom: 5px solid aqua;
	background: rgba(255, 40, 40, 0.5);
	font-size: 1.125em;
	font-weight: bolder;
	padding: 10px;
}

.p3 {
	text-align: left;
	color: silver;
	font-size: 1.125em;
	padding: 10px;
	max-height: 1.7em;
}

.p3 p {
	margin: 0;
	padding: 0;
	display: inline-block;
}

.question {
	text-align: center;
	border: none;
	background-color: transparent;
	color: silver;
	font-size: 1.125em;
	padding: 10px;
	max-width: 8em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.float_right {
	float: right;
}

.question2 {
	color: red !important;
}

.question3 {
	background-color: transparent;
	color: silver;
	font-size: 1em;
	max-width: 8em !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.question:hover {
	color: #67fefe !important;
}

@media only screen and (max-width: 500px) {
	.p2,
	.p2red {
		max-width: 9em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.question {
		max-width: 6em;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.bankgrid {
	display: grid;
	grid-template-columns: 3fr 2fr;
	grid-gap: 0.5em;
	padding-top: 0.5em;
	max-width: 35em;
}

.bankgrid2 {
	display: grid;
	grid-template-columns: 0.5fr 5fr 5fr;
	grid-gap: 0.5em;
	padding-top: 0.5em;
	max-width: 35em;
}

.cu_icons {
	margin: 0;
	display: flex;
	float: inline-end;
}

.cu_icons img {
	max-width: 1.5em;
	max-height: 1.5em;
	margin-top: 0.6em;
	margin-right: 0.5em;
}

.currencygrid {
	width: fit-content;
	display: grid;
	grid-template-columns: 2fr 2fr;
	grid-gap: 0.5em;
	margin: auto;
	margin-bottom: 0.5em;
}

.bankbuttongrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 5px;
	padding-bottom: 10px;
	max-width: 30em;
	margin-left: 5em;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
}

@media only screen and (max-width: 500px) {
	.bankbuttongrid {
		margin-left: 0em;
	}
}

.grid_sep {
	border-bottom: 2px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
}

.centraliser {
	width: fit-content;
	margin: auto;
	padding: 0;
}

.centraliser2 {
	width: 99%;
	margin: auto;
	padding: 0.5em;
	text-align: center;
}

.tb_margin {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.tb_margin2 {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.t_margin {
	margin-top: 0.5em !important;
}

.b_margin {
	margin-bottom: 0.5em;
}

.b_margin1 {
	margin-bottom: 0.2em;
}

.b_margin2 {
	margin-bottom: 1.5em;
}

.c_vmargin {
	margin-top: 1em;
	margin-bottom: 1em;
}

.lr_padding {
	padding-left: 2em;
	padding-right: 2em;
}

.b1 {
	text-align: right;
	color: silver;
	padding: none;
	font-size: 1.125em;
	line-height: 3em;
}

.b2 {
	text-align: right;
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 30px;
	padding: 0px;
	padding-right: 15px;
	color: silver;
	margin-top: 0.3em;
	margin-bottom: 0.3em;
	font-size: 1.125em;
	line-height: 2.2em;
}

.b3 {
	text-align: left;
	color: silver;
	padding: 0;
	margin: 0;
}

.banking {
	width: 150px;
	height: 30px;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	padding-bottom: 5px;
	line-height: 5px;
	margin-right: 5px;
	margin-top: 10px;
}

.logoutbox {
	margin: auto;
	text-align: right;
	margin-right: 5em;
}

/* CSS for the Tabs across the site*/

.tabs {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;
	width: fit-content;
	align-content: center;
	height: fit-content;
	padding: 0;
	margin-top: 10px;
	margin-bottom: 10px;
}

.transfertabs {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 2s reverse;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.875em;
	background: rgba(15, 18, 19, 0.1);
	margin-bottom: 1em;
	height: 2.366em;
	width: 8.405em;
	text-align: center;
	padding: 0.1em;
}

.subtabs {
	margin: auto;
	width: fit-content;
	text-align: center;
	height: 75px;
	padding: 0;
	padding-top: 10px;
	margin-top: 10px;
	margin-bottom: 0;
	border-top: solid 2px rgba(255, 255, 255, 0.08);
}

.tabs2 {
	margin: auto;
	width: fit-content;
	text-align: center;
}

.tab {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 2s reverse;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.875em;
	background: rgba(15, 18, 19, 0.1);
	margin-bottom: 5px;
	height: 2.366em;
	width: 8.405em;
	text-align: center;
	float: left;
	margin-right: 10px;
	margin-left: 10px;
	padding: 10px;
}

@media only screen and (max-width: 500px) {
	.tab {
		width: 7.5em;
	}

	.tab p {
		font-size: 0.8em;
	}
}

.subtab {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 5s reverse;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.25em;
	background: rgba(15, 18, 19, 0.1);
	margin-bottom: 5px;
	height: 45px;
	width: 150px;
	text-align: center;
	float: left;
	margin-right: 10px;
	margin-left: 10px;
}

.tab:hover p.glow {
	animation: glow 1s ease-in-out infinite alternate;
}

.cs_grid {
	width: 270px;
	margin: auto;
	display: grid;
	grid-template-columns: 150px 120px;
	padding: 20px;
	padding-left: 10px;
	border: solid silver 1px;
	border-radius: 20px;
}

.cs_rank {
	text-align: left;
	margin: 0;
	margin-bottom: 5px;
	padding: 0;
	padding-left: 25px;
}

.cs_sn {
	text-align: right;
	margin: 0;
	margin-bottom: 5px;
	padding: 0;
	padding-right: 10px;
}

.cs_header {
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
	padding: 0;
	padding-bottom: 5px;
	font-size: 1.125em;
	border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

/*CSS for the popup modals*/

.popup {
	backdrop-filter: blur(10px) saturate(150%);
	-webkit-backdrop-filter: blur(10px) saturate(150%);
	background-color: rgba(19, 24, 59, 0.95);
	box-shadow: 10px 10px 60px rgba(39, 248, 238, 0.678);
	min-width: 500px;
	height: auto;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	padding: 15px;
	z-index: 5;
}

.checkout {
	backdrop-filter: blur(10px) saturate(150%);
	-webkit-backdrop-filter: blur(10px) saturate(150%);
	background-color: rgba(19, 24, 59, 0.95);
	box-shadow: 10px 10px 60px rgba(39, 248, 238, 0.678);
	width: 120px;
	height: auto;
	top: 0%;
	right: 0%;
	transform: translate(-10%, 10%);
	position: absolute;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	padding: 15px;
	z-index: 5;
	animation: checkout 2s infinite;
}
@-webkit-keyframes checkout {
	0% {
		-webkit-box-shadow: 1px 1px 16px 7px #67fefe;
	}
	70% {
		-webkit-box-shadow: 1px 1px 16px 7px #8a9191;
	}
	100% {
		-webkit-box-shadow: 1px 1px 16px 7px #1607e4;
	}
}
@keyframes checkout {
	0% {
		-moz-box-shadow: 1px 1px 16px 7px #67fefe;
		box-shadow: 1px 1px 16px 7px #67fefe;
	}
	70% {
		-moz-box-shadow: 1px 1px 16px 7px #8a9191;
		box-shadow: 1px 1px 16px 7px #8a9191;
	}
	100% {
		-moz-box-shadow: 1px 1px 16px 7px #1607e4;
		box-shadow: 1px 1px 16px 7px #1607e4;
	}
}

.checkout_collapsed {
	backdrop-filter: blur(10px) saturate(150%);
	-webkit-backdrop-filter: blur(10px) saturate(150%);
	background-color: rgba(19, 24, 59, 0.95);
	box-shadow: 10px 10px 60px rgba(39, 248, 238, 0.678);
	width: 20px;
	height: 20px;
	top: 100%;
	right: 0%;
	transform: translate(-10%, -100%);
	position: absolute;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	padding: 15px;
	z-index: 5;
	animation: checkout 2s infinite;
}

.popup_mobile {
	backdrop-filter: blur(10px) saturate(150%);
	-webkit-backdrop-filter: blur(10px) saturate(150%);
	background-color: rgba(19, 24, 59, 0.95);
	box-shadow: 10px 10px 60px rgba(39, 248, 238, 0.678);
	width: 100%;
	height: auto;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	padding: 15px;
	z-index: 5;
}

.popup_mobile_content div {
	margin-bottom: 0.5em;
}

.btn-close {
	background-color: rgb(19, 24, 59, 0.95) !important;
	border: 3px solid #67fefe !important;
	color: #67fefe !important;
	border-radius: 40px;
	cursor: pointer;
	display: inline-block;
	font-family: arial;
	font-weight: bold;
	position: absolute;
	top: -20px;
	right: -20px;
	font-size: 1.5625em;
	line-height: 20px;
	width: 40px;
	height: 40px;
	text-align: center;
	background-image: url(https://dl.dropboxusercontent.com/s/hd35018ht7kdip2/modalclosebutton.svg?raw=1);
	background-repeat: no-repeat;
	background-size: cover;
	-ms-overflow-style: none;
}

/* Modal css testing */

.darkBG {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100vw;
	height: 100vh;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
}

.centered {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
}

.closeBtn {
	height: 35px;
	width: 35px;
	cursor: pointer;
	color: inherit;
	font-weight: 700;
	padding: 4px 8px;
	border-radius: 1em;
	border: 2px solid #67fefe !important;
	background-color: rgba(19, 24, 59, 0.95) !important;
	font-size: 1.125em;
	transition: all 0.25s ease;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
	position: absolute;
	top: -3px;
	right: -3px;
	align-self: flex-end;
	margin-top: -7px;
	margin-right: -7px;
}

/* // Spinner css // */

.loadingSpinnerContainer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loadingSpinner {
	font-size: 7.5em;
	color: #67fefe;
	animation: spin 1.2s linear infinite;
	line-height: 20px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*CSS for the Leaderboards*/

.leaderboard {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 5s reverse;
	margin-left: 25px;
	margin-right: 25px;
	padding: 25px;
	max-height: fit-content;
	width: fit-content;
	margin: auto;
	margin-bottom: 25px;
	margin-top: 25px;
}

.board {
	margin: auto;
	width: auto;
	text-align: left;
	margin-top: 0em;
}

.lb_listing,
.lb_header {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	grid-row-gap: 0.5em;
}

.lb_header {
	margin-bottom: 0.5em;
	padding-bottom: 0.5em;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
}

.lb_personalrank {
	margin-top: 3em;
}

/*CSS for Homepage*/

.homegraphic {
	max-width: 500px;
	width: 100%;
	margin: auto;
	text-align: center;
	z-index: -5;
}

.homegraphicMobile {
	max-width: 300px;
	width: 90%;
	margin: auto;
	text-align: center;
}

.homegraphic2 {
	width: 100%;
	margin: auto;
	text-align: center;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.homegraphic3 {
	width: max-content;
	margin: auto;
	text-align: center;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.homegraphic img,
.homegraphic2 img,
.homegraphic3 img .homegraphicMobile img {
	border-radius: 1em 2em;
	filter: drop-shadow(0px 0px 7px #b3ffff);
	margin: auto;
	text-align: center;
}

.homegraphic img,
.homegraphic2 img {
	width: 100%;
	height: auto;
}

.homegraphic3 img {
	width: 15em;
	height: auto;
}

.homegraphicMobile img {
	max-width: 100%;
	height: auto;
}

@media screen and (max-width: 1120px) {
	button.tokenadress {
		margin: 0 1em;
		width: 150px;
	}
}

.adresses {
	width: 100%;
	margin: auto;
	text-align: center;
}

.tokenadress {
	width: 250px;
	height: 30px;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	margin: 0 5em;
}

button.tokenadress {
	max-width: 275px;
}

.dynamichomecontainer {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 5s reverse;
	margin-left: 25px;
	margin-right: 25px;
	padding-left: 25px;
	max-height: fit-content;
	width: 90%;
	margin: auto;
	margin-bottom: 25px;
	margin-top: 25px;
}

.h2home {
	margin-left: 10px;
	border-bottom: #67fefe 2px solid;
	border-bottom-right-radius: 1em 4em;
	width: max-content;
	color: #67fefe;
	padding-right: 20px;
}

p.maincontent {
	margin-left: 10px;
	font-family: 'Ubuntu';
	padding-bottom: 30px;
	font-size: 1.25em;
}

.teamcontainer {
	height: 500px;
	width: 100%;
	border: none;
	font-family: 'Ubuntu';
	color: silver;
	overflow: auto;
}

.roadmapcontainer {
	height: 800px;
	width: 100%;
	border: none;
	font-family: 'Ubuntu';
	color: silver;
	overflow: auto;
}

.rm_paragraph {
	margin-left: 2.5em;
}

.hidden {
	display: none;
}

/* CSS for Reports page*/

.report {
	margin-bottom: 5px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 10px;
}

.accordion-content {
	width: 70%;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	border-bottom: 1px solid #67fefe;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
	margin-left: 25px;
	margin-right: 25px;
	padding: 15px;
	padding-left: 25px;
	overflow: hidden;
	transition: max-height 0.2s ease-in-out;
	font-size: 1em;
}

button.accordion {
	width: 90%;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: left;
	padding: 10px 20px;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
}

.iframe {
	border: 2px solid #727b7e;
	border-radius: 1em 4em;
	margin-top: 15px;
	margin-bottom: 5px;
	margin-right: 10px;
	background: rgba(15, 18, 19, 0.75);
	overflow: scroll;
	width: 85%;
	height: 95vh;
	background-color: transparent;
}

.report_link {
	width: auto;
	padding-left: 0.5em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	font-size: 1.25em;
}

.report_link:hover {
	background-color: rgba(255, 255, 255, 0.08);
	color: #67fefe;
	border-radius: 10px;
}

/*CSS for the SN sale fundraiser page/design */

.salesbox {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	max-width: 70%;
	height: fit-content;
	padding: 25px;
	display: block;
	margin: auto;
	margin-top: 25px;
}

.salesmaingoal {
	margin: auto;
	width: 100%;
	border-bottom: silver solid 2px;
	padding-bottom: 25px;
}

.maingoalbarwrapper {
	width: 70%;
	margin: auto;
	margin-bottom: 2em;
}

.maingoalbar {
	width: 40%;
	height: 100%;
	border-radius: 20px;
	background-color: #056023;
}

.subgoals {
	width: 90%;
	margin: auto;
}

.goal {
	margin-top: 10px;
	padding-bottom: 10px;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
}

.subgoalgrid {
	display: grid;
	grid-template-columns: 2fr 4fr 4fr;
	grid-gap: 5px;
	height: 2em;
}

.subtext {
	padding-top: 5px;
}

.subgoalbarwrapper {
	width: 100%;
	margin: auto;
	margin-bottom: 1em;
}

.subgoalbar {
	width: 40%;
	height: 100%;
	border-radius: 20px;
	background-color: #056023;
}

@keyframes animate-stripes {
	0% {
		background-position: 0 0;
	}

	100% {
		background-position: 60px 0;
	}
}

@keyframes auto-progress {
	0% {
		width: 0%;
	}

	100% {
		width: 100%;
	}
}

.progress-bar {
	background-color: #1a1a1a;
	height: 1em;
	margin: auto;
	border-radius: 5px;
	box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}

.stripes {
	background-size: 30px 30px;
	background-image: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.stripes.animated {
	animation: animate-stripes 0.6s linear infinite;
}

.stripes.animated.slower {
	animation-duration: 1.25s;
}

.stripes.reverse {
	animation-direction: reverse;
}

.progress-bar-inner {
	display: block;
	height: 1em;
	width: 0%;
	background-color: #67fefe;
	border-radius: 3px;
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
	position: relative;
	animation: auto-progress 10s infinite linear;
}

.progress-bar-inner2 {
	display: block;
	height: 100%;
	width: 0%;
	background: linear-gradient(
		90deg,
		#ffd33d,
		#ea4aaa 17%,
		#b34bff 34%,
		#01feff 51%,
		#ffd33d 68%,
		#ea4aaa 85%,
		#b34bff
	);
	background-size: 300% 100%;
	border-radius: 3px;
	animation: auto-progress 10s infinite linear;
}

.red {
	background-color: red !important;
}

.blue {
	background-color: blue !important;
}

/*CSS for Nation pages*/

/*.nation_content{
	height: 100%;
	overflow: hidden;
	overflow-y: hidden;
	
}

.nation_scroll{
	height: 100%;
	overflow: scroll !important;
	overflow-y: scroll;
	
}

*/

/*Capital City*/

.centralgraphic {
	width: 98%;
	margin: auto;
	text-align: center;
	border-top: #67fefe solid 2px;
	border-top-right-radius: 1em 4em;
	border-top-left-radius: 1em;
}

.cc_description {
	width: 98%;
	margin: auto;
	text-align: center;
	font-size: 2em;
	border-bottom: #67fefe solid 2px;
	border-bottom-right-radius: 1em;
	border-bottom-left-radius: 1em 4em;
}

.cb_wrapper {
	width: 98%;
	margin: auto;
}

.cc_unit {
	display: flex;
	flex-wrap: wrap;

	width: auto;
	margin: auto;
	margin-top: 2em;
	margin-bottom: 1em;
	border: silver 1px solid;
	border-radius: 2em;
	padding: 1em;
}

.cc_unit_image {
	width: fit-content;
	max-width: 25%;
	padding: 5px;
	border-bottom: #67fefe 1px solid;
	border-right: #67fefe 1px solid;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
}
.cc_unit_image img {
	max-width: 100%;
	max-height: auto;
}

.cc_unit_description {
	max-width: 40%;
	margin: auto;
}

.cc_unit_input {
	width: 3em !important;
	margin-left: 1.5em !important;
	text-align: right !important;
}

.cc_unit_progress {
	width: 30%;
	margin: auto;
}

.cc_unit_prog_grid {
	display: grid;
	grid-template-columns: 1fr 8fr 1fr;
	grid-gap: 0.5em;
}

.cc_unit_prog_grid p {
	margin: auto;
	text-align: center;
}

.center {
	width: 100%;
	margin: auto;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	text-align: center;
}

/*Player City*/

.pc_description {
	width: 98%;
	margin: auto;
	padding: 1em;
	border-top: #67fefe solid 2px;
	border-top-right-radius: 1em 4em;
	border-top-left-radius: 1em;
	border-bottom: #67fefe solid 2px;
	border-bottom-right-radius: 1em;
	border-bottom-left-radius: 1em 4em;
}

.player_buildings {
	width: 98%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;
	margin-top: 1em;
}

.pb_unit {
	margin: 1em;
}

.pb_card {
	width: 300px;
	height: auto;
	padding: 0.5em;
	border: #67fefe solid 1px;
	background-color: rgba(19, 24, 59, 0.5);
}

.corners--round {
	mask-image: radial-gradient(circle at 1.5rem 1.5rem, transparent 1.5rem, gold 0);
	mask-position: -1.5rem -1.5rem;
	mask-repeat: repeat;
}

.pb_card img {
	margin: auto;
	max-width: 100%;
	height: auto;
}

.pb_progressbar {
	width: 99%;
	margin: auto;
	margin-top: -32px;
	border: 2px solid #67fefe;
	background-color: #67fefe;
	border-radius: 5px;
}

.lvl_indicator {
	background-color: #67fefe !important;
	border: 2px solid #67fefe !important;
	color: black !important;
	cursor: pointer;
	display: inline-block;
	font-family: arial;
	font-weight: bold;
	position: relative;
	z-index: 1;
	top: -1px;
	right: -45%;
	font-size: 1em;
	line-height: 20px;
	width: fit-content;
	height: fit-content;
	text-align: center;
	padding: 3px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	-ms-overflow-style: none;
}

.pb_buff {
	margin: auto;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	width: 100%;
	text-align: center;
}

.pb_buttons {
	width: 100%;
	margin: auto;
	margin-top: 1em;
	text-align: center;
}

/*css for scholarships*/

.scholar_content {
	display: flex;
	height: 76vh;
	padding-top: 5px;
}

.leftmenu_scholarships {
	min-width: 300px;
	max-width: 300px;
	background-color: rgba(19, 24, 59, 0.5);
	border-right: 2px solid #67fefe;
	flex-grow: 1;
	position: relative;
}

.leftmenu_scholarships_closed {
	min-width: 20px;
	max-width: 20px;
	background-color: rgba(19, 24, 59, 0.5);
	border-right: 2px solid #67fefe;
	flex-grow: 1;
	position: relative;
}

.leftmenu_button {
	width: 1.7em;
	height: 1.7em;
	background-color: #67fefe;
	color: #000;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}

.leftmb {
	font-size: 1.7em;
	margin: 0 auto;
	text-align: center;
	margin-top: -0.1em;
	cursor: pointer;
	font-weight: bolder;
}

.centraliser22 {
	flex-grow: 1;
	margin: 0.5em;
}

.scholarlisting {
	margin-top: 1em;
}

.scholarlisting2 {
	max-height: 63vh;
	overflow-y: scroll;
}

.listingheader {
	display: grid;
	grid-template-columns: 2fr 4fr 2fr 2fr 2fr;
	text-align: center;
	border-bottom: rgba(255, 255, 255, 0.08) solid 1px;
	padding-bottom: 0.5em;
	margin: 0.5em;
	font-size: 1.25em;
}

.slh_guild,
.slh_description,
.slh_minfame,
.slh_payrate {
	border-right: solid 2px rgba(255, 255, 255, 0.08);
}

.listing_unit {
	display: grid;
	grid-template-columns: 2fr 4fr 2fr 2fr 2fr;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
	margin-left: 0.5em;
	margin-right: 0.5em;
	font-size: 1em;
}

.listing_unit:hover {
	background: rgba(255, 255, 255, 0.08);
	color: #67fefe;
}

.sl_guild,
.sl_description,
.sl_minfame,
.sl_payrate,
.sl_bonus {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5em;
}

.sl_guild {
	margin: auto;
}

.sl_guild img {
	max-width: 5em;
	max-height: 5em;
}

.ap_description {
	display: flex;
	flex-direction: column;
	justify-content: center;

	padding-left: 0.5em;
}

.ap_description_text,
.sl_description_text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.ap_badges {
	margin: 0;
	margin-top: 0.5em;
	display: flex;
}

.ap_badges img {
	max-width: 1.5em;
	max-height: 1.5em;
}

.lm_content {
	margin: 0.5em;
}

.lm_unit {
	margin-bottom: 1em;
}

.lm_unit p {
	width: fit-content;
	margin: 0;
}

/*CSS for the Rewards page*/

.Mobile {
	max-width: 450px;
	width: auto;
	margin: auto;
}

.salebuttonmob {
	width: 140px;
	height: 25px;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	margin: 0;
	padding: 0;
	margin-left: 5px;
	margin-top: 5px;
}

.salewrapper {
	width: auto;
	margin: auto;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
}

.salegrid {
	margin: auto;
	margin-top: 1em;
	margin-bottom: 1em;
	width: 95%;
	/*
	border: 1px solid silver;
	border-radius: 1em;
	*/
}

.sg_unit {
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin-top: 2em;
	width: 95%;
	border-bottom: 2px solid rgba(255, 255, 255, 0.08);
}

.sg_cell2 {
	height: 2em;
	margin-top: -6px;
	padding-bottom: 5px;
}

.sale_p {
	width: 90%;
	margin: auto;
	margin-top: 1.5em;
}

.mainsalescontainer {
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	animation: border 5s reverse;
	margin-left: 25px;
	margin-right: 25px;
	padding-left: 25px;
	max-height: fit-content;
	width: 90%;
	margin: auto;
	margin-bottom: 25px;
	margin-top: 25px;
	padding: 2em;
}

.msc_subgrid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.sales_image {
	margin: auto;
}

.sales_image2 {
	margin: auto;
}

.sales_image2 img {
	max-width: 10em;
}

.salestext {
	max-width: 75%;
	/*CSS for the Rewards page*/
}

.flexwrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.ru_border {
	position: relative;
	margin: 1em;
	width: 30em;
	height: 30em;
}

.ru_wrap {
	position: absolute;
	width: 30em;
	height: 30em;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
}

.trapezium_top {
	position: relative;
	top: 0em;
	left: 0em;
	z-index: 2;
	width: 30em;
	height: 9em;
	clip-path: polygon(2.5% 0%, 97.5% 0%, 67.5% 100%, 32.5% 100%);
	background-image: linear-gradient(to top, rgba(19, 24, 59, 0.95), rgba(19, 24, 59, 0.05));
}

.trapezium_bottom {
	position: relative;
	top: 12em;
	left: 0em;
	width: 30em;
	height: 9em;
	clip-path: polygon(32.5% 0%, 67.5% 0%, 97.5% 100%, 2.5% 100%);
	background-image: linear-gradient(to bottom, rgba(19, 24, 59, 0.95), rgba(19, 24, 59, 0.05));
}

.center_wrapper {
	position: absolute;
	left: 0;
	top: 0;
	width: 30em;
	height: 30em;
}

.trapezium_left {
	position: relative;
	top: 0;
	left: 0;
	z-index: 10;
	width: 9em;
	height: 30em;
	clip-path: polygon(0% 2.5%, 100% 32.5%, 100% 67.5%, 0% 97.5%);
	background-image: linear-gradient(to left, rgba(19, 24, 59, 0.95), rgba(19, 24, 59, 0.05));
}

.trapezium_center {
	position: relative;
	top: -21em;
	left: 9em;
	z-index: 10;
	width: 12em;
	height: 12em;
}

.trapezium_center img {
	max-width: 100%;
	height: auto;
}

.trapezium_right {
	position: relative;
	top: -42em;
	left: 21em;
	z-index: 10;
	width: 9em;
	height: 30em;
	clip-path: polygon(0% 32.5%, 100% 2.5%, 100% 97.5%, 0% 67.5%);
	background-image: linear-gradient(to right, rgba(19, 24, 59, 0.95), rgba(19, 24, 59, 0.05));
}

.centraliser_2d {
	display: flex;
	width: 100%;
	height: 100%;
	text-align: center;
	justify-content: center;
	margin: auto;
}

.block {
	display: block;
	margin: auto;
}

.trap_content {
	margin-bottom: 0.5em;
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.sale_p2 {
	width: 90%;
	margin: auto;
	margin-top: 1.5em;
}

.salesgrid {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: fit-content;
	margin: auto;
	margin-top: 1em;
	margin-bottom: 1em;
}

.sale_unit {
	min-width: 240px;
	max-width: 240px;
	border: 3px solid #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 2em;
	border-bottom-left-radius: 2em;
	border-bottom-right-radius: 1em 4em;
	backdrop-filter: blur(10px) saturate(150%);
	-webkit-backdrop-filter: blur(10px) saturate(150%);
	background-color: rgba(19, 24, 59, 0.5);
	margin-left: 2em;
	margin-bottom: 2em;
	padding: 10px;
}

.su_image {
	padding-top: 25px;
	margin: auto;
	text-align: center;
	padding: 0;
	margin-top: 5px;
	margin-bottom: 0.5em;
}

.su_image img {
	width: 200px;
	height: 200px;
	border-radius: 10px;
}

.su_cell {
	width: fit-content;
	height: 2em;
	margin: auto;
	padding-bottom: 5px;
}

.salebutton {
	width: 200px;
	height: 25px;
	color: silver;
	border: 2px double #67fefe;
	text-align: center;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	padding: 0;
	margin: auto;
	margin-top: 5px;
}

/*CSS for admin pages*/

.admin_tabs {
	width: 98%;
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1em;
}

.admin_content {
	display: flex;
	flex-wrap: wrap;
	width: 98%;
	border: 1px silver solid;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	padding: 0.5em;
}

.ac_sub {
	width: 100%;
}

.transactions_grid {
	width: 98%;
}

.transactions_grid_mobile {
	max-width: 98%;
}

.ul_header,
.ul_listing {
	display: grid;
	grid-template-columns: 3fr 3fr 3fr 2fr 2fr 2fr 2fr 2fr 2.5fr;
	grid-gap: 0.5em;
}

.ul_listing button {
	font-family: 'Ubuntu';
	color: silver;
	font-size: 1em;
}

.ul_listing div,
.ul_header div {
	text-align: center;
}

.tg_header,
.tg_listing,
.tg_filters {
	display: grid;
	grid-template-columns: 3.5fr 2fr 2fr 2fr 2fr 2fr 2fr;
	grid-gap: 0.5em;
}

.tg_list4,
.tg_head4 {
	display: grid;
	grid-template-columns: 2fr 2fr 2fr 3fr;
	grid-gap: 0.5em;
}

.tg_note {
	grid-column-start: 1;
	grid-column-end: 7;
	font-weight: 600;
}

.tg_admin_header,
.tg_admin_listing {
	display: grid;
	grid-template-columns: 3.5fr 2fr 2fr 2fr 2fr;
	grid-gap: 0.5em;
}

.tg_admin_note {
	grid-column-start: 1;
	grid-column-end: 6;
	font-weight: 600;
}

.tg_access_header,
.tg_access_listing {
	display: grid;
	grid-template-columns: 3.5fr 2fr 2fr 2fr;
	grid-gap: 0.5em;
}

.tg_user_listing,
.tg_user_header {
	display: grid;
	grid-template-columns: 3.5fr 2fr 2fr 2fr 2fr 2fr;
	grid-gap: 0.5em;
}

.tg_header,
.tg_user_header,
.ul_header,
.tg_user_header_mobile,
.tg_admin_header,
.tg_access_header,
.tg_head4 {
	font-size: 1.25em;
	margin-bottom: 0.5em;
	padding-left: 0.5em;
	padding-bottom: 0.2em;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
}

.tg_listing,
.tg_user_listing,
.lb_listing,
.ul_listing,
.tg_user_listing_mobile,
.tg_admin_listing,
.tg_access_listing,
.tg_list4 {
	font-size: 1em;
	padding-left: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
}

.tg_user_listing_mobile,
.tg_user_header_mobile {
	display: grid;
	grid-template-columns: 1.1fr 0.8fr 1fr;
}

.greentext {
	color: #169f41 !important;
}
.redtext {
	color: rgb(240, 29, 29) !important;
}

.tg_ul_mob_amount {
	text-align: center;
}

.lm_ul {
	margin: 0;
	padding-top: 0.5em;
}

.lm_ul li {
	list-style-type: none;
}

.game_list,
.currency_list {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 10px;
	font-size: 1.2em;
	width: 250px;
	color: silver;
	padding: 0.1em;
	padding-left: 0.3em;
	margin-top: 0.5em;
	margin-left: 1em;
}

option.selection {
	background: #04176bb6;
	font-size: 1em;
}

.slidecontainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 250px;
	margin-left: 1em;
}

.slider {
	width: 80%; /* Full-width */
	height: 25px; /* Specified height */
	background: rgba(255, 255, 255, 0.08);
	outline: none; /* Remove outline */
	opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
	-webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
	transition: opacity 0.2s;
	color: #67fefe;
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.form-group2 {
	width: 250px;
	margin-left: 1em;
	display: flex;
	justify-content: space-between;
}
.currency_form_input {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding: 0.1em;
	padding-left: 0.5em;
	font-size: 1em;
	margin: auto;
	color: silver;
	width: 5em;
	outline: none;
}

.form {
	margin-left: 1em;
	margin-right: 1em;
	margin-top: 0.5em;
	padding: 0.1em;
	font-size: 1em;
}
.tg_listing:hover,
.tg_user_listing:hover,
.ul_listing:hover,
.tg_user_listing_mobile:hover,
.lb_listing:hover,
.tg_list4:hover {
	background-color: rgba(255, 255, 255, 0.08);
}

.tg_filters {
	font-size: 1em;
	margin: 0.5em;
}

.tg_footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0.5em;
	margin-top: 1em;
}

.tsa_unit,
.tsa_unit2 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 2fr;
	grid-gap: 0.5em;
}

.tsa_unit2 {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
}

.tsa_unit_mobile {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0.5em;
	margin-bottom: 0.5em;
}

.note {
	max-width: 15em;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.cuss_form {
	width: auto;
	margin: auto;
}

.cussf_selectors {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 0.5em;
	max-width: fit-content;
	margin: auto;
}

.cu_card_wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

@media (max-width: 768px) {
	.cu_card_wrap {
		margin-bottom: 8em;
	}
}

.cu_card,
.usl_card {
	border-top: 2px double #67fefe;
	border-bottom: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	width: 15em;
	margin: 1.5em;
	padding: 0.5em;
}

.usl_card {
	width: 20em !important;
}

.cu_card_content,
.usl_card_content {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 0.1em;
	margin-left: 1em;
}

.redirect_notice {
	float: left;
	width: 100%;
	margin: auto;
}

.ccc_border {
	border: 1px solid silver;
	border-radius: 1em;
	padding: 0.5em;
	margin: 0.5em;
}

.cu_card_image {
	width: 10em;
	height: 10em;
	margin: auto;
	text-align: center;
}

.cu_card_image img {
	width: 100%;
	height: auto;
}

.divider {
	width: 99%;
	margin-bottom: 3em;
}

.silver_b {
	color: inherit;
	font-size: inherit;
	font: inherit;
	cursor: zoom-in;
}

.silver_b:hover {
	color: #67fefe;
}

/*CSS for the FAQ*/

.lmf_item {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.875em;
	background-image: linear-gradient(to right, #130d4c, #3293bd7b, #243e3e4b);
	margin-bottom: 0.5em;
	height: 2.366em;
	width: 8.405em;
	text-align: center;
	float: left;
	padding: 1em;
	border-radius: 0.75em;
	border: 1px solid #01feff;
}

.lmf_item:hover {
	transform: scale(1.05);
}

.lmf_item:hover p {
	color: #67fefe;
}

.faq_container {
	display: flex;
	height: 85vh;

	padding-top: 1em;
}

button.faq_question {
	display: grid;
	grid-template-columns: 1fr 9fr;
	grid-column-gap: 1em;
	width: 97%;
	color: silver;
	border-bottom: solid 2px rgba(255, 255, 255, 0.08);
	text-align: left;
	padding: 0.5em 1em;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
}

.faq_left {
	text-align: right !important;
	font-size: 2.5em !important;
	margin: auto 0;
}

.faq_right {
	margin: auto 0;
}

button.faq_question:hover {
	color: #67fefe;
	background-color: rgba(255, 255, 255, 0.08);
}

button.faq_question.is-open {
	color: #67fefe;
}

.faq_answer {
	display: grid;
	grid-template-columns: 1fr 9fr;
	grid-column-gap: 1em;
	width: 85%;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	border-bottom: 1px solid #67fefe;
	border-bottom-right-radius: 2em;
	border-bottom-left-radius: 2em;
	margin-left: 1.5em;
	margin-right: 1.5em;
	padding: 15px;
	padding-left: 25px;
	overflow: hidden;
	transition: max-height 0.2s ease-in-out;
	font-size: 1em;
	background: rgba(19, 24, 59, 0.5);
}

.faq_item {
	margin-left: 0.5em;
}

.faq_contentcontainer {
	height: 71.5vh;
	width: 99%;
	border-top: 2px double #67fefe;
	border-bottom: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	padding: 0.5em;
	margin: 0.5em;
	font-family: 'Ubuntu';
	color: silver;
	overflow: auto;
}

/*CSS for media pages*/

.twitter-embed {
	width: 25em;
	margin: inherit !important;
}

.smlink {
	font-size: 3em;
}

.smlink:hover {
	color: #01feff;
	transform: scale(1.05);
}

/* css for holo card */
.card {
	--radius: 3%;
}

/* end CSS for holo card */

/* Open Chips CSS begin */
.ship-box {
	border: 2px solid #727b7e;
	border-radius: 1em 4em;
	overflow: scroll;
	margin: 1em;
	padding: 1em;
}

.ship-container {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	margin: 1em;
	flex-wrap: wrap;
}

.ships {
	margin: 1em;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: 15%;
}

.ships img {
	width: 50%;
	height: 50%;
	display: block;
	margin: 1em auto;
}

.openchip {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding-bottom: 1em;
}

.glitch {
	position: relative;
	width: 20em;
	height: 45vh;
	background: url(https://dl.dropboxusercontent.com/s/ym4vhzkco3jj5pb/ascopen.png?raw=1);
	background-size: 100%;
	transition: filter 0.2s ease-in-out;
	filter: hue-rotate(0deg);
}

.glitch:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(https://dl.dropboxusercontent.com/s/ym4vhzkco3jj5pb/ascopen.png?raw=1);
	background-size: 100%;
	opacity: 0.5;
	mix-blend-mode: hard-light;
}

.glitch.glitching:before {
	animation: glitch 0.2s linear infinite;
}

@keyframes glitch {
	0% {
		background-position: 0 0;
		filter: hue-rotate(0deg);
	}
	10% {
		background-position: 5px 0;
	}
	20% {
		background-position: -5px 0;
	}
	30% {
		background-position: 15px 0;
	}
	40% {
		background-position: 5px 0;
	}
	50% {
		background-position: -25px 0;
	}
	60% {
		background-position: -50px 0;
	}
	70% {
		background-position: 0 -20px;
	}
	80% {
		background-position: -60px -20px;
	}
	81% {
		background-position: 0 0;
	}
	100% {
		background-position: 0 0;
		filter: hue-rotate(360deg);
	}
}

/* Open Chips CSS end */

/* User Items CSS start */

.collectionsselect {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	height: 25px;
	font-size: 1.25em;
	margin: auto;
	color: silver;
	outline: none;
}

.collectionsselect option {
	background-color: rgba(3, 4, 39, 0.9);
	color: #67fefe;
	text-align: left;
	border: none;
	border-radius: 2em;
}

.useritems_grid {
	width: 98%;
	height: 50%;
	margin: 0.5em;
	display: grid;
	align-self: center;
	grid-template-areas: 'image description stats';
	grid-template-columns: 30% 30% 30%;
}

.useritemsimg {
	grid-area: image;
	display: flex;
	flex-direction: column;
	width: 50%;
	margin: 1em auto;
}

.useritemsdescription {
	grid-area: description;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.useritemsstats {
	grid-area: stats;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

/* User Items CSS end */
