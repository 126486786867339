.mobilemenu {
	position: fixed;
	right: 0.5em;
	top: 0.5em;
	width: 5em;
	height: 5em;
	z-index: 1;
}

.menu_button {
	display: flex;
	flex-direction: column;
	padding-top: 1em;
	text-align: center;
	justify-content: center;
	padding-left: 0em;
}

.menuoption {
	display: flex;
	text-align: center;
	margin: 0 auto;
	font-size: 1.4em;
}

.svg_size svg {
	width: 3em;
	height: 3em;
	filter: drop-shadow(3px 3px 2px rgba(3, 4, 39, 0.5)) drop-shadow(-3px 3px 2px rgba(3, 4, 39, 0.5))
		drop-shadow(3px -3px 2px rgba(3, 4, 39, 0.5)) drop-shadow(-3px -3px 2px rgba(3, 4, 39, 0.5));
}

.menutext {
	text-shadow: -3px -3px 2px rgba(3, 4, 39, 0.5), 3px -3px 2px rgba(3, 4, 39, 0.5),
		-3px 3px 2px rgba(3, 4, 39, 0.5), 3px 3px 2px rgba(3, 4, 39, 0.5);
}

.menuicon {
	font-family: 'SN Icons';
}

.mobilemenutabs {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background-color: rgba(3, 4, 39);
	display: flex;
	justify-content: center;
}

.navlist {
	/* fit all the content in the window */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
