p.checkmark {
	color: rgb(79, 250, 79);
	width: fit-content;
	display: inline-block;
	padding-left: 5px;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.container {
	display: flex;
	width: 100%;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	padding: 50px 0;
	box-sizing: border-box;
}

.timeline {
	width: 80%;
	max-width: 950px;
	height: auto;
	margin: 0 auto;
	position: relative;
	box-sizing: border-box;
}

.timeline ul {
	list-style: none;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.timeline ul li {
	padding: 20px;
	margin-bottom: 20px;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	background-color: rgba(19, 24, 59, 0.5);
	box-sizing: border-box;
}

.timeline-content {
	font-size: 1em;
	margin-bottom: 10px;
	box-sizing: border-box;
	padding: 5px;
}

.timeline-content h1 {
	font-size: 1.5625em;
}

@media only screen and (min-width: 768px) {
	.timeline::before {
		content: '';
		position: absolute;
		height: 100%;
		width: 2px;
		left: 50%;
		transform: translateX(-50%);
		background-color: silver;
	}
	.timeline ul li {
		width: 50%;
		margin-bottom: 50px;
		position: relative;
	}
	.timeline ul li:nth-child(odd) {
		float: left;
		clear: right;
		transform: translateX(-30px);
	}
	.timeline ul li:nth-child(even) {
		float: right;
		clear: left;
		transform: translateX(30px);
	}
	.timeline ul li::after {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		background-color: silver;
		border-radius: 50%;
		top: 50%;
	}
	.timeline ul li:nth-child(odd)::after {
		transform: translate(50%, -50%);
		right: -30px;
	}
	.timeline ul li:nth-child(even)::after {
		transform: translate(-50%, -50%);
		left: -30px;
	}
	.timeline ul li:hover::after {
		background-color: #67fefe;
	}
}
