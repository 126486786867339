.MobileBoSmenu {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 5em;
	background-color: rgba(3, 4, 39);
	padding-bottom: 1em;
}

.menu_button {
	font-size: 1em;
}

.menuopen {
	font-size: 1.875em;
	display: flex;
	text-align: center;
	margin: 0 auto;
}

.svg_size svg {
	width: 3em;
	height: 3em;
}

.mobileaccounttabs {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background-color: rgba(3, 4, 39);
	display: flex;
	justify-content: center;
	align-items: center;
}
