.asset {
	display: grid;
	grid-template-columns: 3em 6em;
	margin-bottom: 0.5em;
}

.assetselect {
	color: silver;
	font-size: 1em;
	padding-top: 0.4em;
	margin-right: 0.5em;
	margin-top: 0;
	margin-bottom: 0;
}

.ship-containermobile {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	margin: 1em;
	flex-wrap: wrap;
}

.image-containermobile {
	max-width: 70%;
	max-height: 70%;
	object-fit: contain;
}

.shipsmobile {
	margin: 1em;
	align-items: center;
	text-align: center;
}

.selectgame {
	width: 100%;
	height: 1.5em;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	padding-bottom: 1em;
	line-height: 5px;
	margin-right: 5px;
}
