.bankgrid {
	display: grid;
	grid-template-columns: 3fr 1.5fr;
	grid-gap: 0.5em;
	padding-top: 0.5em;
	max-width: 35em;
}

.logsicon {
	font-size: 1em;
	color: rgb(134, 233, 247);
}
