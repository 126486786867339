/*Begin CSS for CEX layout*/

.cexback {
	width: 100vw;
	height: 100vh;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-image: url(https://dl.dropboxusercontent.com/s/pdr0agnmoy9holk/forge.png?raw=1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.cex_gridcontainer {
	width: 98%;
	height: 75vh;
	margin: 0.5em;
	display: grid;
	grid-template-areas:
		'orders pair select '
		'orders pair create'
		'myorders myorders myorders';
	grid-template-rows: 30% 40% 30%;
	grid-template-columns: 60% 5% 35%;
}

.cexorders {
	grid-area: orders;
}

.cexorders img {
	/* position the image centered on the right side of cexorders */
	position: absolute;
	top: 50%;
	right: 52%;
	transform: translate(0%, -50%);
	opacity: 0.85;
	filter: drop-shadow(0px 0px 7px #b3ffff);
}

.cexpair {
	grid-area: pair;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2em;
	color: #9de3ff;
	font-weight: bold;
}
.cexselect {
	grid-area: select;
}

.createbutton {
	width: 7.5em;
	height: 1.5em;
	color: silver;
	border: 2px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	text-align: center;
	font-size: 1.25em;
	cursor: pointer;
	transition: background-color 0.2s linear;
	background-color: inherit;
	padding-bottom: 1px;
	margin-bottom: 1em;
	line-height: 5px;
	margin-right: 5px;
}

.createorder_gridcontainer {
	width: 50%;
	margin: 10px;
	row-gap: 0.2em;
	margin-bottom: 1em;
	display: grid;
	grid-template-columns: 50% 50%;
}

.cexcreate {
	grid-area: create;
}

.cexmyorders {
	grid-area: myorders;
}

.panel {
	box-shadow: #01feff 0px 0px 3px;
	overflow: auto;
	overflow-x: hidden;
}

.panel2 {
	box-shadow: #01feff 0px 3px 3px;
	overflow: auto;
	overflow-x: hidden;
}

.quantumhub {
	color: #01feff;
	animation: glow 1s ease-in-out infinite alternate;
}

.tradingpairhorizontal {
	width: max-content;
	color: #01ff9e;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 30px;
	font-size: 1em;
	padding: 10px;
	padding-right: 20px;
}

.f1_cex {
	color: silver;
	font-size: 1em;
	text-align: left;
	padding-top: 0.4em;
	margin-right: 0.5em;
	margin-top: 0;
	margin-bottom: 0;
}

.form-input5 {
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	height: 25px;
	font-size: 1.25em;
	margin: auto;
	margin-left: 0.5em;
	color: silver;
	outline: none;
}

.form-input5 option {
	background-color: rgba(3, 4, 39, 0.9);
	color: #67fefe;
	text-align: left;
	border: none;
	border-radius: 2em;
}

.form-input4 {
	width: 95%;
	border: 1px solid transparent;
	background: rgba(255, 255, 255, 0.08);
	border-radius: 40px;
	padding-left: 20px;
	padding-right: 20px;
	height: 25px;
	font-size: 1.25em;
	margin: auto;
	margin-left: 0.5em;
	color: silver;
	outline: none;
}

.form-input4 option {
	background-color: rgba(3, 4, 39, 0.9);
	color: #67fefe;
	text-align: left;
	border: none;
	border-radius: 2em;
}

.cex_myordersgrid {
	display: grid;
	margin: 10px;
	row-gap: 0.2em;
	grid-template-columns: 2.5fr 3.5fr 2fr 2fr 2fr 2fr 2fr;
}

.cex_myordersgrid.header {
	font-size: 1.25em;
	padding-bottom: 0.5em;
	font-weight: bold;
	border-bottom: solid 3px rgba(255, 255, 255, 0.08);
}

/* end CSS for CEX layout*/
