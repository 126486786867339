/*CSS for cardpack sale*/

.cp_flexcontainer {
	width: 98%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: auto;
	margin-top: 1em;
}

.cp_iconsL {
	top: 0;
	left: 0;
	z-index: -0.5;
	transform: translate(0, 0);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0.25;
}

.cp_iconsR {
	top: 0;
	right: 0;
	z-index: -0.5;
	transform: translate(0, 0);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0.25;
}

.cp_unit {
	margin: 1em !important;
}

.cp_unit2 {
	margin: 2em !important;
}

.loginsynth {
	margin-top: 0.5em;
	margin-bottom: 3em;
}

.cp_card {
	width: 17em;
	height: fit-content;
	padding: 1.5em;
	margin-bottom: 3em;
	border: #67fefe solid 1px;
	background-color: rgba(19, 24, 59, 0.5);
}

.corners--round {
	mask-image: radial-gradient(circle at 1.5rem 1.5rem, transparent 1.5rem, gold 0);
	mask-position: -1.5rem -1.5rem;
	mask-repeat: repeat;
}

.cp_card img {
	position: relative;
	transform: translate(0%, -20%);
	margin: auto;
	max-width: 100%;
	height: auto;
	border-radius: 2em;
}

.cp_title {
	text-align: center;
	margin: 0;
	margin-top: 0.25em;
	font-size: 1.5em;
	color: silver;
}

.chipcount {
	position: relative;
	transform: translate(0%, 45%);
	font-size: 2em;
	font-weight: bold;
	text-shadow: #000000 0px 0px 5px;
	z-index: 100;
	text-align: center;
}

.chiptextarea {
	position: relative;
	transform: translate(0%, -35%);
	margin-bottom: -5em;
	margin-top: 1em;
}

.synthchip img {
	border-radius: 1em 2em;
	filter: drop-shadow(0px 0px 10px #b3ffff);
	margin: auto;
	text-align: center;
}

.synthchipback {
	width: 100vw;
	height: 100vh;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background-image: url(https://dl.dropboxusercontent.com/s/ibsb5wori0qwqbv/synthchipback.png?raw=1);
	background-size: stretch;
	background-repeat: no-repeat;
	background-position: center;
}

.synthchipsale {
	margin-top: -0.25em;
	letter-spacing: 10px;
	margin-bottom: -0.5em;
	text-shadow: #01feff 0px 0px 10px;
}

.synthbutton {
	font-size: 1em;
	color: silver;
	border: #67fefe solid 2px;
	border-radius: 10px;
	margin: 0;
}

.synthbutton:hover {
	background-color: #67fefe;
	color: black;
	box-shadow: rgba(79, 248, 234, 0.35) 0 -25px 18px -14px inset, rgba(79, 248, 234, 0.25) 0 1px 2px,
		rgba(79, 248, 234, 0.25) 0 2px 4px, rgba(79, 248, 234, 0.25) 0 4px 8px,
		rgba(79, 248, 234, 0.25) 0 8px 16px, rgba(79, 248, 234, 0.25) 0 16px 32px;
	transform: scale(1.05);
}
