.cappmessage {
	margin: auto;
	text-align: center;
	margin-bottom: 20px;
	color: silver;
}

.cappmessagep {
	display: inline-block; /* Ensures the text is centered on each line */
	text-align: left;
}
