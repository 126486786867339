table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

td {
	text-align: left;
	padding: 8px;
	border-bottom: 1px solid #ddd;
}

th {
	font-weight: bold;
	font-size: 1.4em;
	padding-left: 0.3em;
	padding-right: 0.3em;
	text-align: center;
}
