.disclaimer {
	backdrop-filter: blur(10px) saturate(150%);
	-webkit-backdrop-filter: blur(10px) saturate(150%);
	background-color: rgba(19, 24, 59, 0.95);
	box-shadow: 10px 10px 60px rgba(39, 248, 238, 0.678);
	height: auto;
	border-top: 5px double #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 1em;
	border-bottom: 5px double #67fefe;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em 4em;
	border-left: 1px solid #67fefe;
	border-right: 1px solid #67fefe;
	padding: 15px;
	z-index: 5;
}
