.teamwrapper {
	margin: 0;
	padding: 0;
}

.agliner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.agliner ul {
	display: flex;
	align-items: center;
	list-style: none;
	max-width: 100%;
	overflow-x: scroll;
	scroll-behavior: smooth;
	padding: 5px;
}

.teammobile {
	flex-direction: column;
	align-items: center;
}

.agliner li {
	height: 450px;
	border: none;
}

.colored_card {
	min-width: 300px;
	max-width: 325px;
	min-height: 95%;
	border: 3px solid #67fefe;
	border-top-left-radius: 1em 4em;
	border-top-right-radius: 2em;
	border-bottom-left-radius: 2em;
	border-bottom-right-radius: 1em 4em;
	background-color: rgba(19, 24, 59, 0.5);
	margin-left: 25px;
	padding: 10px;
}

.avatar,
.avatar2 {
	padding-top: 25px;
	margin: auto;
	text-align: center;
	padding: 0;
	margin-top: 5px;
}

.avatar img {
	width: 200px;
	height: 200px;
	border-radius: 10px;
}

.avatar2 img {
	width: 250px;
	height: 200px;
	border-radius: 10px;
}

.Name {
	margin: auto;
	text-align: center;
	padding: 0;
	margin-top: 5px;
}

.position {
	margin: auto;
	text-align: center;
	padding: 0;
	margin-top: 5px;
}

.quote {
	margin: auto;
	padding: 1em;
	text-align: left;
	max-width: 85%;
	margin-top: 1em;
	font-size: 1.125em;
	white-space: normal;
	border: silver 1px solid;
	border-radius: 1em;
}

.agliner .btn {
	position: absolute;
	padding: 0;
	top: 50%;
	transform: translateY(-50%);

	height: 30px;
	width: 30px;
	color: #67fefe;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.5);
	background-position: 50% 50%;
	background-repeat: no-repeat;

	z-index: 1;
}

.btnnext {
	height: 30px;
	width: 30px;
	color: #67fefe;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.5);
	position: relative;
	top: 50%;
	transform: translateX(1em);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	z-index: 1;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg width="18" height="18" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><title>Shape</title><path d="M25.557 14.7L13.818 2.961 16.8 0l16.8 16.8-16.8 16.8-2.961-2.961L25.557 18.9H0v-4.2z" fill="%23FFF" fill-rule="evenodd"/></svg>');
	right: 10px;
}

.btnprev {
	height: 30px;
	width: 30px;
	color: #67fefe;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.5);
	position: relative;
	top: 50%;
	transform: translateX(-1em);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	z-index: 1;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg width="18" height="18" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><title>Shape</title><path d="M33.6 14.7H8.043L19.782 2.961 16.8 0 0 16.8l16.8 16.8 2.961-2.961L8.043 18.9H33.6z" fill="%23FFF" fill-rule="evenodd"/></svg>');
	left: 10px;
}
