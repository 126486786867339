.gamecontainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 1em;
}

.gamesicon {
	cursor: pointer;
	margin-bottom: 1em;
}

.gamesicon img {
	border-radius: 3em;
	border: 0.5em solid #67fefe;
	max-width: 300px;
	height: auto;
}

.gamesicon:hover img {
	box-shadow: 0px 0px 20px 0px #67fefe;
}

.description_content {
	max-width: 600px;
	border: 1px silver solid;
	border-radius: 1em;
	padding: 2em;
	margin-left: 1em;
}

.hide {
	display: none;
}

.description_content button {
	margin-top: 1em;
}

.gamename {
	margin: 0.5em;
	font-weight: bold;
	text-align: right;
}

.gamestudio {
	margin: 0.5em;
	text-align: right;
}

@media (max-width: 768px) {
	.gamecontainer {
		flex-direction: column;
		align-items: center;
		margin-bottom: 8em;
	}

	.description_content {
		max-width: 100%;
	}
}
